.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whatsapp-widget {
  text-decoration: none;
  position: fixed;
  bottom: 0;
  left: 10px;
  z-index: 100;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #1cc3b2;

  #whatsapp-icon {
    font-size: 3.5rem;
    background-color: #4ac75a;
    color: #ffffff;
    border-radius: 50%;
  }

  &:hover {
    cursor: pointer;
    background-color: #cdeae7;
    border-radius: 50%;
    transition-duration: 500ms;
  }

  span {
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 475px) {
  .whatsapp-widget {
    span {
      display: none;
    }
  }
}
